@import 'variables/variables.scss';

.ant-checkbox-wrapper {
  align-items: center !important;
}

.Incident-test {
  height: 100%;
  background: linear-gradient(155.28deg, #FFC1DA 15.76%, #99CBEC 122.17%, #99CBEC 122.17%);
  max-height: calc(100% - 60px);

  .ant-picker-clear {
    width: 48px;
    height: 35px;
    top: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    right: -9px;
    padding-right: 12px;
    z-index: 99999;
    background: #fff;

    svg {
      width: 12px;
      height: 12px;
    }
  }

  .custom-select {
    .ant-select-clear {
      width: 48px;
      height: 35px;
      top: 12px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      right: 1px;
      padding-right: 12px;
      z-index: 99999;
      background: #fff;

      svg {
        width: 12px;
        height: 12px;
      }
    }
  }

  @import 'variables/variables.scss';

  .row {
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;

    .col {
      max-width: 600px;
      width: 100%;
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .container {
      background-color: #fff;
      border-radius: 2.5 * $basic-space;
      // box-shadow: 0px $basic-space 2 * $basic-space #f3f3f3;
      // padding-top: 6 * $basic-space;
      // padding-left: 5 * $basic-space;
      // padding-right: 5 * $basic-space;
      // padding-bottom: 4 * $basic-space;

      .body {
        padding-top: 6 * $basic-space;
        padding-left: 5 * $basic-space;
        padding-right: 5 * $basic-space;
        padding-bottom: 4 * $basic-space;
      }

      & {
        .list-question {
          display: flex;
          align-items: center;
          margin-bottom: 3.5 * $basic-space;
        }

        .form {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;

          .title {
            font-size: $font-size-large;
            line-height: 1.6;
            text-align: left;
            color: #404040;
          }

          .report_title {
            font-family: $font-family;
            font-style: normal;
            font-weight: bold;
            font-size: $font-size-x-large;
            line-height: 1.5;
            color: #404040;
          }

          .report_message {
            font-size: $font-size-normal;
            line-height: 1.5;
            color: #404040;
            margin: 0;
            padding: 0;
            padding-bottom: $basic-space !important;
          }

          .form-item {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .btn-finish {
            box-shadow: unset;
            border-color: unset;
            border: 1px solid $primary-color-button !important;
            background-color: $primary-color-button;
            width: 100%;
            height: 48px;
            border-radius: $basic-space;
            color: #ffffff;
            font-family: $font-family;
            font-style: normal;
            font-weight: normal;
            font-size: $font-size-x-large;
            line-height: 1.1;
            margin-top: 2.5 * $basic-space;
          }

          .btnupload {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 48px;
            font-size: $font-size-large;
            line-height: 1.6;
            box-sizing: border-box;
            border-radius: $basic-space;
            color: #404040;
          }

          .btndropdown {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 48px;
            font-size: $font-size-large;
            line-height: 1.6;
            box-sizing: border-box;
            border-radius: $basic-space;
            color: #777777;

            & {
              .anticon {
                width: 100%;
                text-align: right;
              }
            }
          }

          .input {
            height: 48px;
            min-height: 48px !important;
            border-radius: 1.25 * $basic-space;
            font-size: $font-size-bigger;
            font-family: $font-family;
            font-style: normal;
            font-weight: normal;
            font-size: $font-size-large;
            line-height: 1.6;
            cursor: pointer;
            color: #404040;
          }

          .input-area {
            color: #404040;
            height: 48px;
            border-radius: 1.25 * $basic-space;
            font-size: $font-size-bigger;
            font-family: $font-family;
            font-style: normal;
            font-weight: normal;
            font-size: $font-size-large;
            line-height: 1.6;
            cursor: pointer;
          }
        }
      }
    }
  }

  .ant-form-item-label {
    word-break: break-word !important;
    white-space: pre-wrap;
    // margin-bottom: 3 * $basic-space;
  }

  .ant-checkbox+span {
    white-space: pre-wrap;
  }

  .before {
    .ant-form-item-label>label {
      height: unset;
      display: inline-block;
    }
  }

  .ant-checkbox-wrapper {
    word-break: break-word;
  }

  .textbox,
  .email,
  .checkbox,
  .checkboxlist,
  .rating,
  .dropdown,
  .date {
    .ant-form-item-label>label {
      height: unset;
      display: inline-block;
    }
  }

  & {

    .textbox,
    .email,
    .checkbox,
    .checkbox-required,
    .checkboxlist,
    .rating,
    .dropdown,
    .date {
      .ant-form-item-label>label::after {
        display: inline-block;
        margin-right: 4px;
        color: #ff4d4f;
        font-size: 14px;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: '(*)';
      }

      .ant-checkbox-wrapper::after {
        display: inline-block;
        margin-right: 4px;
        color: #ff4d4f;
        font-size: 14px;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: '(*)';
        min-width: 35px
      }
    }

    .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      content: '';
    }

    .ant-select-selection-item {
      border-radius: $basic-space;
      height: 32px;
      display: flex;
      margin-right: 3 * $basic-space;
      align-items: center;
      font-family: $font-family;
      font-size: $font-size-large;
      line-height: 1.6;

      color: #404040;
    }

    .ant-select-selection-item-remove {
      display: none;
    }

    .ant-select-multiple .ant-select-selector::after {
      color: #404040;
      z-index: 1000;
    }

    .ant-form-item {
      display: flex;
      flex-direction: column;
      margin-bottom: 4 * $basic-space;
    }

    .ant-form-item-label {
      text-align: left;
    }

    .ant-form-item-control {
      height: 48px;

      @media not all and (min-resolution: 0.001dpcm) {
        @supports (-webkit-appearance: none) {
          height: initial;
          min-height: 48px;
        }
      }
    }

    .ant-checkbox {
      box-sizing: border-box;
      border-radius: $basic-space;
    }

    .ant-select-selector {
      min-height: 48px !important;
      height: unset;
      border: 0.25 * $basic-space solid #e1e1e1;
      box-sizing: border-box;
      border-radius: $basic-space !important;
      display: flex;
      align-items: center;
    }

    .ant-picker {
      height: 48px;
      width: 100%;
      border-radius: $basic-space !important;
      box-sizing: border-box;
    }
  }
}

.menu-radio {
  width: 100%;
  height: 100%;
  max-height: 200px;
  top: 1.5 * $basic-space;
  background: #ffffff;
  padding: 0 !important;
  border: 0.25 * $basic-space solid #e1e1e1;
  border-radius: $basic-space !important;

  & {
    .ant-radio-group {
      width: 100%;
    }

    .menu-item {
      margin: 0;
      padding: 0 6 * $basic-space;
      height: 50px;
      margin: 0 !important;
      box-sizing: border-box;
      border-bottom: 0.25 * $basic-space solid #e1e1e1;
      font-size: $font-size-large;
      line-height: 1.6;
      display: flex;
      align-items: center;
      justify-content: space-between;

      & {
        .ant-dropdown-menu-title-content {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}

.menu-checkbox {
  width: 100%;
  height: 100%;
  max-height: 200px;
  top: 1.5 * $basic-space;
  background: #ffffff;
  padding: 0 !important;
  border: 0.25 * $basic-space solid #e1e1e1;
  border-radius: $basic-space !important;

  & {
    .ant-checkbox-group {
      width: 100%;
    }

    .menu-item {
      padding: 0 6 * $basic-space;
      height: 50px;
      margin: 0 !important;
      box-sizing: border-box;
      font-size: $font-size-large;
      line-height: 1.6;
      display: flex;
      align-items: center;
      justify-content: space-between;

      & {
        .ant-dropdown-menu-title-content {
          display: flex;
          justify-content: space-between;
        }
      }
    }

    .menu-item:last-child {
      border-bottom: none;
    }
  }
}

.ant-picker-date-panel {
  width: 300px;
  height: 372px;
}

.ant-picker-header-super-prev-btn {
  display: none !important;
}

.ant-picker-header-super-next-btn {
  display: none !important;
}

.ant-picker-header-view {
  flex: none;
}

.ant-picker-header {
  border-bottom: none;
  padding-left: 12 * $basic-space !important;
  padding-right: 10.5 * $basic-space !important;
  width: 100%;
  border-bottom: 0.25 * $basic-space solid #ebebf0;
}

.ant-picker-today-btn {
  font-size: $font-size-bigger;
  color: #21c0f6;
  font-weight: 500;
  line-height: 1.5;
}

.ant-picker-body {
  width: 100%;
  height: 100%;
  padding-left: 4 * $basic-space !important;
  padding-bottom: 4 * $basic-space !important;
  padding-right: 4.25 * $basic-space !important;
}

.ant-picker-content {
  width: 100% !important;
  height: 100%;
}

.ant-picker-header-view {
  font-size: $font-size-bigger;
  line-height: 1.5;
  margin: 0 9.75 * $basic-space;
}

.ant-picker-header-next-btn,
.ant-picker-header-prev-btn {
  color: #21c0f6 !important;
  font-size: $font-size-big !important;
}

.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
  border: 0.25 * $basic-space solid #1a94ff;
  background: transparent;
}

.ant-picker-cell .ant-picker-cell-inner {
  min-width: 40px;
  height: 40px;
  line-height: 40px;
}

.ant-picker-cell-inner::before {
  border: none !important;
}

.ant-picker-cell-today {
  color: #21c0f6;
}

.ant-select-item {
  padding: 0 6 * $basic-space;
  min-height: 50px;
  height: unset;
  margin: 0 !important;
  box-sizing: border-box;
  border-bottom: 0.25 * $basic-space solid #e1e1e1;
  font-size: $font-size-large;
  line-height: 1.6;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ant-select-item-option-content {
  display: flex;
  justify-content: space-between;
  word-break: break-word;
  white-space: unset;

  .ant-radio-wrapper {
    margin-right: 0 !important;
    margin-left: 8px !important;
    align-items: center !important;
  }

  .demo-option-label-item {
    width: 95%;
  }
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: #fff;
}

.ant-select-item-option-state {
  display: none;
}

.ant-upload.ant-upload-select-picture-card {
  width: 100%;
  height: 48px;
}

.ant-spin-nested-loading {
  width: 100%;
  height: 100%;
}

.ant-spin-container {
  width: 100%;
  height: 100%;
}

.containerUpload {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-btn {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $basic-space;
  background: #fff;

  .title-upload {
    font-size: $font-size-large;
    line-height: 1.6;
    color: $primary-color-title;
  }
}

.icon-btn {
  width: 100%;
  text-align: right;
}

.ant-upload.ant-upload-select-picture-card {
  border: none;
}

.select-dropdown {
  .ant-select-selection-item {
    background: #fff;
    justify-content: start;
  }
}

.ant-select-item:last-child {
  border-bottom: none;
}

.question-text {
  position: relative;
  font-size: $font-size-normal;
  padding-bottom: 4px;
}

.question-text::before {
  display: inline-block;
  position: absolute;
  color: #ff4d4f;
  font-size: $font-size-normal;
  font-family: $font-family;
  line-height: 1;
  content: '*';
  left: 1.5 * $basic-space;
  top: 1.25 * $basic-space;
}

.before {
  .ant-form-item-label>label::after {
    display: none;
  }
}

.upload-incidents {
  cursor: pointer;
  border: 1px dashed;
  min-height: 12 * $basic-space;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: rgb(217, 217, 217);
  border-radius: $basic-space;

  .ant-space-item {
    display: flex;
    align-items: center;
    font-family: $font-family;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #404040;
  }

  .title-upload {
    margin-left: 8px;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .close-container-uploaded {
    position: relative;

    .close {
      position: absolute;
      right: -30px;
      top: -8px;
    }
  }
}

.item-btn {
  .ant-form-item-label>label::after {
    display: none;
  }
}

.active-checked {
  .ant-radio-inner {
    background: #1890ff;

    &::after {
      background-color: #ffffff !important;
    }
  }
}