@import 'variables/variables.scss';
.layout_container {
  height: 100%;
  background: #f9f9f9;
  max-height: 650px;
  overflow: auto;
  .container {
    justify-content: center;
    width: 100%;
    .card_passcode {
      width: 100%;
      max-width: 390px;
      display: flex;
      flex-direction: column;
    }

    .card {
      width: 100%;
      height: 100%;
      padding: 6 * $basic-space 5.5 * $basic-space 5 * $basic-space 5.75 * $basic-space;
      background-color: rgba(255, 255, 255, 0.9);
      border-radius: 2.5 * $basic-space;
      box-shadow: 0px $basic-space 2 * $basic-space #f3f3f3;
    }
    .card_title {
      color: $primary-color-title;
      font-family: $font-family;
      font-style: normal;
      font-weight: bold;
      font-size: $font-size-x-large;
      line-height: 1.5;
      margin-bottom: 4 * $basic-space;
    }
    .card_text {
      font-family: $font-family;
      font-size: $font-size-normal;
      line-height: 1.8;
      color: $primary-color-title;
      word-spacing: 2px;
      white-space: pre-wrap;
    }
    .text_second {
      @media screen and (max-height: 600px) {
        margin-bottom: 2.5 * $basic-space !important;
      }
    }
    .text_goback {
      font-family: $font-family;
      font-size: $font-size-large;
      line-height: 1.6;
      color: $primary-color-title;
      word-spacing: 2px;
      padding-top: 6 * $basic-space;
      padding-bottom: 4 * $basic-space;
      cursor: pointer;
      @media screen and (max-height: 600px) {
        padding-top: 2 * $basic-space;
        padding-bottom: 2 * $basic-space;
      }
      @media screen and (max-height: 400px) {
        padding-top: $basic-space;
        padding-bottom: $basic-space;
      }
    }
    .btn {
      box-shadow: unset;
      border-color: unset;
      border: 1px solid $primary-color-button !important;
      background-color: $primary-color-button;
      width: 100%;
      height: 48px;
      border-radius: $basic-space;
      color: #ffffff;
      font-family: $font-family;
      font-weight: 500;
      font-size: $font-size-x-large;
      line-height: 1.1;
      margin-top: 4 * $basic-space;
    }

    .btn_remove {
      color: $primary-color-button;
      width: 100%;
      height: 48px;
      border: 0.375 * $basic-space solid $primary-color-button;
      box-sizing: border-box;
      border-radius: $basic-space;
      font-family: $font-family;
      font-weight: 500;
      font-size: $font-size-bigger;
      line-height: 1.25;
      margin-top: 4 * $basic-space;
    }
  }
  .text_second {
  }
}
