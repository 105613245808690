@import 'variables/variables.scss';
.container-fluid {
  height: 100%;
  .layout_private {
    padding-top: 4 * $basic-space;
    padding-bottom: 3 * $basic-space;
    padding-right: 4 * $basic-space;
    padding-left: 4 * $basic-space;

    .main_body {
      min-width: 1136px;
      @media only screen and (min-width: 768px) and (max-width: 1025px) {
        max-width: 100%;
        min-width: initial;
      }
    }

    @media screen and (max-height: 500px) {
      padding-right: 2 * $basic-space;
      padding-left: 2 * $basic-space;
      padding-top: 4 * $basic-space;
      padding-bottom: 3 * $basic-space;
    }
    .ant-layout-content {
      margin-left: 16px;
    }
  }
}
