@import 'variables/variables.scss';
.container_sos {
  height: 100%;
  overflow: hidden;
  .text_attechment {
    font-family: $font-family;
    font-style: normal;
    font-weight: normal;
    font-size: $font-size-small;
    line-height: 1.5;
    color: #777777;
    display: flex;
    gap: 20px;
    margin-top: 20px;

    .wrap {
      display: flex;
      align-items: center;
      gap: 10px;

      .new {
        height: 20px;
        width: 31px;
        border-radius: 4px;
        background: #21c0f6;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        color: #fff;
        justify-content: center;
        font-family: $font-family;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 15px;
        text-align: justify;
        color: #ffffff;
        padding: 2px 4px;
      }
    }

    .wrap-file {
      display: flex;
      gap: 10px;

      img {
        height: 12px;
        width: 12px;
        margin-top: 2px;
      }
    }
  }
  & {
    .col {
      height: 100%;
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 1200px) {
        margin-bottom: 2.5 * $basic-space;
      }
    }
    ::-webkit-scrollbar-thumb {
      background: #888;
    }
  }
}
.card_content {
  height: 100%;

  border-radius: 2 * $basic-space !important;
  border: 0.25 * $basic-space solid #e1e1e1;
  .ant-card-head {
    flex: 0 1 15 * $basic-space;
  }
  .ant-scroll-number {
    right: -6 * $basic-space;
  }
  .ant-scroll-number-only-unit {
    color: #fff;
  }
  .container_title {
    font-family: $font-family;
    border-radius: 2 * $basic-space 2 * $basic-space 0 0;
    font-weight: bold;
    font-size: $font-size-x-large;
    line-height: 1.5;
    text-align: justify;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-card-head-wrapper {
    width: 100%;
  }
  .ant-card-head-title {
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .main-content {
    display: flex;
    align-items: center;
  }
  .image {
    border-radius: 1.25 * $basic-space;
    height: 80px;
    object-fit: cover;
  }
  .ant-card-body {
    overflow: hidden;
    padding: 0;
    max-height: 94%;

    @media screen and (min-height: 900px) and (max-height: 1000px) {
      max-height: 93%;
    }
    @media screen and (min-height: 800px) and (max-height: 900px) {
      max-height: 92%;
    }
    @media screen and (max-height: 800px) {
      max-height: 91%;
    }
  }
  .infinite-scroll-component {
    overflow: hidden !important;
  }
  .infinite-scroll-component__outerdiv {
    width: 100%;
    padding-bottom: 20px;
    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) {
        padding-bottom: 120px;
      }
    }
    .infinite-scroll-component {
      max-height: 100%;
    }
  }
  .link:last-child .ant-card-meta {
    border-bottom: unset;
  }
  .ant-card-meta {
    margin: 0;
    border-bottom: 0.25 * $basic-space solid rgba(225, 225, 225, 1);
    padding-bottom: 5 * $basic-space;
    padding-left: 6.25 * $basic-space;
    padding-top: 5 * $basic-space;
    padding-right: 6 * $basic-space;
  }
  .ant-card-meta-title {
    white-space: unset;
    font-weight: bold;
    font-size: $font-size-normal;
    line-height: 1.5;
    font-family: $font-family;
  }
  .ant-card-meta-description a {
    font-family: $font-family;
    font-size: $font-size-large;
    line-height: 1.6;
    text-decoration-line: underline;
    color: #404040;
    font-size: $font-size-small;
    line-height: 1.5;
  }
  .ant-card-meta-avatar {
    border-radius: 1.25 * $basic-space;
    padding-right: 2.5 * $basic-space;
  }
  .scrollable {
    width: 100%;
    overflow: auto;
    min-height: 635px;
  }
  .spin-content {
    width: 100%;
    position: absolute;
    bottom: 50px;
  }
}
