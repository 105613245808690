@import 'variables/variables.scss';
@import 'variables/variables.scss';
.layout_term {
  height: calc(100% - 112px);

  background-color: $primary;
  .ant-spin-nested-loading,
  .ant-spin-container {
    height: 100%;
  }
  .container {
    background-color: $primary;
    max-height: 654px;

    justify-content: center;

    .card {
      width: 100%;
      height: 100%;

      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    .card-img {
      position: relative;
      width: 100%;
    }
    .card-top {
      width: 100%;
      position: relative;
    }

    .top-image {
      border-radius: 2.5 * $basic-space 2.5 * $basic-space 0 0;
      height: 150px;
      width: 100%;
      cursor: pointer;
    }

    .logo {
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 138px;
      height: 138px;

      border-radius: 50%;
      background-color: #fff;
      font-size: $font-size-bigger;
      color: $primary-color-title;
      font-weight: 500;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: $primary-color-logo;
      cursor: pointer;
    }

    .form {
      display: flex;
      box-shadow: 0px 4px 8px #f3f3f3;
      background-color: rgba(255, 255, 255, 0.9);
      border-radius: 2.5 * $basic-space;
      flex-direction: column;
      width: 100%;
      height: 100%;
      padding: 0 10 * $basic-space;
      padding-top: 20 * $basic-space;
      padding-bottom: 20px;

      .title-term {
        cursor: pointer;
        font-family: $font-family;
        font-style: normal;
        font-size: $font-size-large;
        line-height: 20px;
        text-decoration: underline;
        margin: 0;
        font-weight: 500;
      }
      .title_accept {
        font-family: $font-family;
        font-style: normal;
        font-size: $font-size-large;
        line-height: 1.6;
        margin: 0 $basic-space;
        size: $font-size-small;
      }
      .title {
        font-family: $font-family;
        font-style: normal;
        font-weight: 500;
        font-size: $font-size-large;
        line-height: 1.6;
      }
      .orzgation_choice {
        font-family: $font-family;
        font-style: normal;
        font-weight: bold;
        font-size: $font-size-xx-large;

        margin: 5 * $basic-space 0;
      }
      .item-form {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 2.5 * $basic-space;
      }
      .form-title {
        font-family: $font-family;
        font-style: normal;
        font-weight: normal;
        font-size: $font-size-large;
        line-height: 22px;
        color: $primary-color-title;
      }
      .title-accept {
        margin: 0;
        font-family: $font-family;
        font-style: normal;
        font-weight: 500;
        font-size: $font-size-large;
        line-height: 20px;
        color: #404040;
      }
      .btn {
        box-shadow: unset;
        border-color: unset;
        border: 1px solid $primary-color-button !important;
        background-color: $primary-color-button;
        width: 100%;
        height: 56px;
        max-width: 320px;
        border-radius: $basic-space;
        color: #ffffff;
        font-family: $font-family;
        font-style: normal;
        font-weight: normal;
        font-size: $font-size-x-large;
        line-height: 1.1;
      }
    }

    .btn-check {
      margin-right: $basic-space;
      span {
        border-radius: $basic-space;
      }
    }
  }

  .title-back {
    font-family: $font-family;
    font-style: normal;
    font-weight: normal;
    font-size: $font-size-large;
    line-height: 22px;
    padding: 4 * $basic-space 0;
    cursor: pointer;
  }

  .spin-auth {
    height: 100%;
  }
  .ant-checkbox-checked::after {
    border: none;
  }
  .item-form {
    display: flex;
    justify-content: center;
    align-items: center;
    .ant-form-item-control-input-content {
      display: flex !important;
    }
    @media screen and (max-height: 550px) {
      margin: 0 !important;
    }

    .ant-col {
      align-items: center;
      display: flex;
    }
    .ant-form-item-explain-error {
      max-width: 320px;
    }
  }
}
.footer_term {
  padding-top: 4 * $basic-space;
  padding-bottom: 3.5 * $basic-space;
  text-align: center;
  background: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $font-size-large;
  line-height: 1.6;

  & {
    .footer_title {
      margin: 0;
      font-size: $font-size-large;
      margin-left: 2.5 * $basic-space;
      text-decoration: underline;
      cursor: pointer;
      color: $primary-color-title !important;
    }
  }
}
.term-col {
  height: 100%;
  max-width: 600px !important;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
}
.checkedBox {
  display: flex;
}
.container-checkbox {
  .item-form {
    margin: 0;
  }
  .ant-form-item-control-input-content {
    display: flex;
    justify-content: center;
  }
}
