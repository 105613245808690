@import 'variables/variables.scss';
.layout {
  height: 100%;
  background: #f9f9f9;
  max-height: 650px;
  overflow: auto;

  .container {
    justify-content: center;
    width: 100%;
    .card_success {
      width: 100%;
      height: 100%;
      max-height: 402px;
      max-width: 390px;
      @media screen and (max-height: 400px) {
        max-height: 320px;
      }
      display: flex;
      flex-direction: column;
    }
    .card {
      width: 100%;
      height: 100%;
      padding: 56px 24px;

      @media screen and (max-height: 400px) {
        padding: 30px 24px;
      }
      background-color: rgba(255, 255, 255, 0.9);
      border-radius: 2.5 * $basic-space;
      box-shadow: 0px 4px 8px #f3f3f3;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .card_title {
      color: $primary-color-title;
      font-family: $font-family;
      font-style: normal;
      font-weight: bold;
      font-size: $font-size-x-large;
      line-height: 1.5;
      margin-bottom: 8 * $basic-space;
    }
    .card_text {
      font-family: $font-family;
      font-size: $font-size-large;
      line-height: 1.6;
      color: $primary-color-title;
      word-spacing: 2px;
      white-space: pre-wrap;
    }
    .btn {
      box-shadow: unset;
      border-color: unset;
      border: 1px solid $primary-color-button !important;
      background-color: $primary-color-button;
      width: 100%;
      height: 48px;
      border-radius: 1.25 * $basic-space;
      color: #ffffff;
      font-family: $font-family;
      font-weight: 500;
      font-size: $font-size-bigger;
      line-height: 1.25;
      margin-top: 6 * $basic-space;
    }
    .btn_back {
      font-family: $font-family;
      font-size: $font-size-large;
      line-height: 1.6;
      color: $primary-color-title;
      word-spacing: 2px;
      padding-top: 6 * $basic-space;
      padding-bottom: 4 * $basic-space;
      cursor: pointer;

      @media screen and (max-height: 600px) {
        padding-top: 2 * $basic-space;
        padding-bottom: 2 * $basic-space;
      }
      @media screen and (max-height: 400px) {
        padding-top: $basic-space;
        padding-bottom: $basic-space;
      }
    }
  }
}
