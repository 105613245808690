@import 'variables/variables.scss';
.main {
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 2 * $basic-space;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-clip: content-box;
  }
  & {
    .inifinite-scroll {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    .left {
      flex-direction: row;
      .ant-space-item {
        width: 100%;
        .orzgantion_chat {
          .ant-space-item {
            width: unset;
          }
        }
      }
    }
    .main_space {
      flex-direction: column;
      align-items: flex-start;
      margin-top: 5 * $basic-space;
      .ant-space-item:last-child {
        align-self: flex-end;
        margin-top: 0px !important;
        gap: 0px !important;
      }
      .flex-chat {
        display: flex;
        flex-direction: column;
      }
      .message-left {
        display: flex;
        align-items: flex-end;
        .text_time {
          font-size: $font-size-small;
          line-height: 1.5;
          text-align: right;
          color: #a8a8a8;
          font-family: $font-family;
          margin-left: 2.75 * $basic-space;
        }
      }
      .message-right {
        display: flex;
        align-items: flex-end;
        flex-direction: row-reverse;
        .text_time {
          font-size: $font-size-small;
          line-height: 1.5;
          text-align: right;
          color: #a8a8a8;
          font-family: $font-family;
          margin-right: 2.75 * $basic-space;
        }
      }
    }
    .main_space:first-child {
      margin-top: 0;
      gap: 0px;
    }
    .orzgantion_chat {
      max-width: 80%;
      white-space: pre-line;
      padding: 3 * $basic-space;
      background: #e1e1e1;
      border-radius: 2 * $basic-space;
      position: relative;
      word-break: break-word;
      white-space: pre-line;

      & {
        .text_time {
          .ant-space-item {
            overflow: hidden;
            text-overflow: clip;
          }
          font-size: $font-size-small;
          line-height: 1.5;
          text-align: right;
          color: #a8a8a8;
          position: absolute;
          right: -7.5 * $basic-space;
          bottom: 0;
          font-family: $font-family;
        }
      }
    }
    .my_chat {
      max-width: 90%;
      min-width: 60px;
      padding: 2.5 * $basic-space 3 * $basic-space;
      background: $primary-color;
      border-radius: 3 * $basic-space;
      position: relative;
      align-self: flex-end;
      white-space: pre-line;
      display: flex;
      flex-direction: column;

      & {
        .ant-space-item {
          overflow: hidden;
          text-overflow: clip;
        }
        .text_time {
          font-size: $font-size-small;
          line-height: 1.5;
          text-align: right;
          color: #a8a8a8;
          position: absolute;
          left: -7.5 * $basic-space;
          bottom: 0;
          font-family: $font-family;
        }
        .text_chat {
          max-width: 900px;
          overflow: hidden;
          font-size: $font-size-large;
          line-height: 1.6;
          color: #ffffff;
          font-family: $font-family;
          word-break: break-all;
        }
      }
    }
  }
}
