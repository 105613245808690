@import 'variables/variables.scss';
.contentReport {
  // fix full screen
  background: #ffffff;
  .container {
    margin-top: 1.25 * $basic-space;
    background: #fff;
    height: calc(100vh - 90px);
    justify-content: center;
    .col {
      max-width: 514px;
      display: flex;
      flex-direction: column;
    }
    .card {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .title {
      margin-top: 10.75 * $basic-space;
      margin-bottom: 6 * $basic-space;
      color: $primary-color-title;
      font-family: $font-family;
      font-style: normal;
      font-weight: bold;
      font-size: $font-size-x-large;
      line-height: 1.5;
    }
    .text {
      display: flex;
      justify-content: center;
      font-family: $font-family;
      font-size: $font-size-large;
      line-height: 1.6;
      color: $primary-color-title;
      white-space: pre-line;
    }
    .btn {
      box-shadow: unset;
      border-color: unset;
      border: 1px solid $primary-color-button !important;
      margin-top: 10 * $basic-space;
      background-color: $primary-color-button;
      max-width: 343px;
      height: 48px;
      border-radius: 1.25 * $basic-space;
      color: #ffffff;
      font-family: $font-family;
      font-weight: 500;
      font-size: $font-size-bigger;
      line-height: 1.25;
      width: 100%;
    }
  }

  .container_text {
    width: 100%;
    border-radius: $basic-space;
    margin-top: 6 * $basic-space;
    background: #e9f9fe;
    padding: 4 * $basic-space;
    display: flex;
    flex-direction: column;
    span{
      word-break: break-all;
    }
  }
}
