@import 'variables/variables.scss';

.incident-detail {
  height: 100%;
  background-color: $primary;
  max-height: calc(100% - 128px);
  & {
    .ant-form-item-label label {
      white-space: pre-wrap;
    }
    .ant-select-multiple .ant-select-selection-item {
      height: 32px;
      margin-top: 0;
      min-width: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $font-family !important;
    }
    .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
      display: inline-block;
      margin-right: 4px;
      color: #ff4d4f;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: '(*)';
    }
    .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      content: '';
    }
    .checkbox-required {
      .ant-checkbox-wrapper::after {
        display: inline-block;
        margin-right: 4px;
        color: #ff4d4f;
        font-size: 14px;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: '(*)';
        min-width: 35px
      }
    }
    .ant-spin-container {
      display: flex;
      justify-content: center;
    }
    .ant-upload.ant-upload-select-picture-card {
      width: 100%;
      border: unset;
      height: 48px;
    }
    .ant-select-selection-item {
      border-radius: $basic-space;
      height: 32px;
      display: flex;
      align-items: center;
      margin-right: 3 * $basic-space;
      font-family: $font-family !important;

      font-size: $font-size-normal;
      line-height: 1.6;

      color: #404040;
    }
    .ant-select-selection-item-remove {
      display: none;
    }
    .ant-select-selection-item-content {
      color: #404040;
    }
    .ant-select-multiple .ant-select-selector::after {
      color: #404040;
      z-index: 1000;
    }
    .ant-form-item {
      display: flex;
      flex-direction: column;
      margin-bottom: 4 * $basic-space;
      @media not all and (min-resolution: 0.001dpcm) {
        @supports (-webkit-appearance: none) {
          height: 100%;
        }
      }
    }
    .ant-form-item-label {
      text-align: left;
      word-break: break-word !important;
      white-space: unset;
      margin-bottom: 12px;
    }
    .ant-checkbox-wrapper {
      text-align: left;
      word-break: break-word;
      white-space: unset;
    }
    .ant-form-item-label > label {
      font-family: $font-family !important;
      height: unset;
      display: inline-block;
    }
    .ant-form-item-control {
      height: 48px;
      @media not all and (min-resolution: 0.001dpcm) {
        @supports (-webkit-appearance: none) {
          height: 100% !important;
        }
      }
    }
    .ant-checkbox {
      box-sizing: border-box;
      border-radius: $basic-space;
    }
    .ant-select-selector {
      min-height: 48px;
      border: 0.25 * $basic-space solid #e1e1e1;
      box-sizing: border-box;
      border-radius: $basic-space !important;
      display: flex;
      align-items: center;
    }
  }
  .btn-check {
    .ant-checkbox-inner {
      border-radius: 50%;
      width: 20px;
      height: 20px;
    }
    .ant-checkbox-inner::after {
      top: 46%;
      left: 25%;
    }
  }
  .ant-select-selection-item {
    background: #f5f5f5 !important;
  }
  .ant-picker-disabled {
    width: 100%;
    height: 48px;
    border-radius: $basic-space;
  }

  .ant-select-item-option-content {
    display: flex;
    justify-content: space-between;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background: #fff;
  }

  .ant-select-item-option-state {
    display: none;
  }

  .ant-upload.ant-upload-select-picture-card {
    width: 100%;
    height: 48px;
  }

  .ant-spin-nested-loading {
    width: 100%;
    height: 100%;
  }
  .ant-spin-container {
    width: 100%;
    height: 100%;
  }

  .containerUpload {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .upload-btn {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $basic-space;
  }

  .icon-btn {
    width: 100%;
    text-align: right;
  }

  .ant-upload.ant-upload-select-picture-card {
    border: none;
  }

  .ant-select-item:last-child {
    border-bottom: none;
  }

  .question-text {
    position: relative;
    font-size: $font-size-normal;
    padding-bottom: 4px;
  }
  .question-text::before {
    display: inline-block;
    position: absolute;
    color: #ff4d4f;
    font-size: $font-size-normal;
    font-family: $font-family;
    line-height: 1;
    content: '*';
    left: 1.5 * $basic-space;
    top: 1.25 * $basic-space;
  }
  .dropdown-display {
    display: none;
  }
  .spin {
    display: flex;
    justify-content: center;
  }

  .qs_list {
    display: flex;
    font-size: $font-size-normal;

    h5.ant-typography {
      font-size: $font-size-normal;
    }
  }
  .ant-select-selection-item {
    padding: 2 * $basic-space 0;

    border-radius: $basic-space !important;
  }
  .ant-select-selection-overflow-item {
    padding: $basic-space 0;
  }
  .ant-select-selection-overflow-item-suffix {
    display: none;
  }
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  line-height: unset !important;
}
.select-dropdown,
.select-rating {
  .ant-select-selector {
    height: unset !important;
    word-break: break-word !important;
    .ant-select-selection-item {
      white-space: normal;
      height: unset;
      min-height: 32px;
      word-break: break-word !important;
    }
  }
}
.checkbox-detail {
  .ant-checkbox-inner {
    background-color: #f5f5f5 !important;
  }
  .checkbox-text {
    font-family: $font-family;
    font-style: normal;
    font-weight: normal;
    font-size: $font-size-large;
    line-height: 1.425 * $font-size-large;
    color: #404040;
  }
}
