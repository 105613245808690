@import 'variables/variables.scss';
.Modal {
  border-radius: 2 * $basic-space;
  top: 35% !important;
  .TextModal {
    width: 100%;
    font-family: $font-family;
    font-style: normal;
    font-weight: normal;
    font-size: $font-size-large;
    line-height: 1.6;
    color: $primary-color-title;
    padding: 0 5 * $basic-space;
    text-align: center;
  }

  .ButtonModal {
    flex-basis: 50%;
    height: 12 * $basic-space;
    width: 100%;
    border-radius: $basic-space;
    font-size: $font-size-bigger;
    line-height: 1.25;
    font-family: $font-family;
    font-style: normal;
    font-weight: normal;
  }

  .ButtonOK {
    flex-basis: 50%;
    height: 12 * $basic-space;
    width: 100%;
    border-radius: $basic-space;
    font-size: $font-size-bigger;
    line-height: 1.25;
    font-family: $font-family;
    font-style: normal;
    font-weight: normal;
    background: #21c0f6;
    box-shadow: unset;
    border-color: unset;
    border: 1px solid $primary-color-button !important;
  }
}
