@import 'variables/variables.scss';
.check-message {
  background: $primary;
  .container {
    padding-top: 6 * $basic-space;
    justify-content: center;
    width: 100%;
    .card-check {
      width: 100%;
      max-width: 390px;

      display: flex;
      flex-direction: column;
    }
    .card {
      width: 100%;
      padding: 6 * $basic-space 5.5 * $basic-space 5 * $basic-space 5.75 * $basic-space;
      background-color: rgba(255, 255, 255, 0.9);
      border-radius: 2.5 * $basic-space;
      box-shadow: 0px 4px 8px #f3f3f3;
    }
    .title {
      color: $primary-color-title;
      font-family: $font-family;
      font-style: normal;
      font-weight: bold;
      font-size: $font-size-x-large;
      line-height: 1.5;
      margin-bottom: 5 * $basic-space;
    }
    .text {
      font-family: $font-family;
      font-size: $font-size-large;
      line-height: 1.6;
      color: $primary-color-title;
      word-spacing: 2px;
      cursor: pointer;
      padding-top: 6 * $basic-space;
      padding-bottom: 4 * $basic-space;
    }
    .btn {
      box-shadow: unset;
      border-color: unset;
      border: 1px solid $primary-color-button !important;
      background-color: $primary-color-button;
      width: 100%;
      height: 48px;
      border-radius: 1.25 * $basic-space;
      color: #ffffff;
      font-family: $font-family;
      font-weight: 500;
      font-size: $font-size-bigger;
      line-height: 1.25;
      margin-top: 5 * $basic-space;
    }
    .form {
      display: flex;
      flex-direction: column;
      width: 100%;
      .Title {
        font-family: $font-family;
        font-style: normal;
        font-weight: bold;
        font-size: $font-size-large;
        line-height: 1.6;
      }
      .ant-form-item-has-error {
        margin-bottom: 9.5 * $basic-space !important;
      }
      .form-item {
        width: 100%;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 3 * $basic-space;
        .ant-form-item-explain-error {
          margin-top: $basic-space !important;
        }
      }

      .input {
        height: 48px;
        border-radius: $basic-space;
        font-family: $font-family;
        border: 0.375 * $basic-space solid #e1e1e1;
        font-size: $font-size-large;
        line-height: 1.6;
        cursor: pointer;
      }
    }
  }
}
