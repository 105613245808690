@import 'variables/variables.scss';
.standby-notice-success,
.standby-notice-error {
  .ant-message-notice-content {
    min-width: 650px;
    border-radius: $basic-space;

    .ant-message-custom-content {
      display: flex;
      .content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .msg {
          width: 600px;
          text-align: left;
          margin-left: 2.5 * $basic-space;
          font-style: normal;
          font-weight: normal;
          font-size: $font-size-bigger;
          line-height: 1.5;
          /* identical to box height, or 25px */

          letter-spacing: 0.1px;

          /* Text / Primary */

          color: #343434;
        }
        .close-icon {
          cursor: pointer;
        }
      }
      .anticon-check-circle,
      .anticon-close-circle {
        display: flex;
        align-items: center;
      }
    }
  }
}

.standby-notice-success {
  .ant-message-notice-content {
    border: 0.25 * $basic-space solid #34c759;
    background-color: #eaffe2;
  }
}

.standby-notice-error {
  .ant-message-notice-content {
    border: 0.25 * $basic-space solid #ff3b30;
    background-color: #ffe7e7;
  }
}
.notice-error-class {
  .ant-notification-notice-content {
    .ant-notification-notice-message {
      color: red;
    }
  }
}
