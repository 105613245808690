@import 'variables/variables.scss';

.home_component {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 2 * $basic-space;
  box-shadow: 0px $basic-space 2 * $basic-space #f3f3f3;

  .Oval {
    position: absolute;
    max-width: 300px;
    max-height: 300px;
    width: 100%;
    height: 100%;
    border-radius: 50%;

    @media screen and (max-height: 600px) {
      max-width: 200px;
      max-height: 200px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    & {
      .img-oval {
        border-radius: 150px;
        width: 300px;
        height: 300px;

        @media screen and (max-height: 600px) {
          max-width: 200px;
          max-height: 200px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .logo_image {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      .ant-typography {
        position: absolute;
        left: 50%;
        bottom: 20%;
        transform: translate(-50%, -50%);
        font-size: $font-size-x-big;
        line-height: 1.5;
        color: #404040;
      }
    }
  }

  .space-logo {
    .ant-space-item:last-child {
      display: flex;
      justify-content: center;
      font-family: Hiragino Maru Gothic Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #404040;
    }
  }

  .card_image {
    position: relative;
    width: 100%;
    height: 60%;
  }

  .top_image {
    border-radius: 2 * $basic-space 2 * $basic-space 0 0;
    width: 100%;
    height: 100%;
    cursor: unset;

    @media screen and (max-width: 400px) {
      height: 115px;
    }
  }

  .logo {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 300px;
    max-height: 360px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

    .btn-insident-test {
      position: absolute;
      bottom: 0;
      border: 2px solid #fff;
      height: 40px;
      background-color: #30b0e5;
    }

    .btn-insident-test-success {
      position: absolute;
      bottom: 0;
      border: 0;
      height: 40px;
      background-color: #efefef;
      width: 156px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
    }

    .logo_image {
      cursor: pointer;
      width: 93px;
      height: 93px;
    }
  }

  .content {
    width: 100%;
    height: 40%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding: 8.75 * $basic-space 0;

    .cardbottom {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    .cardbottom_title {
      margin-bottom: 3 * $basic-space;
      font-family: $font-family;
      font-style: normal;
      font-weight: bold;
      font-size: $font-size-x-large;
      line-height: 1.5;
      color: $primary-color-title;
    }

    .cardbottom_text_without_time {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }

    .cardbottom_text {
      max-width: 60%;
      font-family: $font-family;
      font-style: normal;
      font-weight: normal;
      font-size: $font-size-large;
      line-height: 1.6;
      color: $primary-color-title;
    }

    .cardbottom_button {
      cursor: unset;
      color: $primary-color-title;
      font-size: $font-size-large;
      line-height: 1.6;
      display: flex;
      align-items: center;
      text-align: center;
      font-family: $font-family;
      border: 0.25 * $basic-space solid #21c0f6;
      border-radius: $basic-space;
      box-sizing: border-box;
      background: rgba(33, 192, 246, 0.1);
      margin: 10.5 * $basic-space 0;
      padding: 2 * $basic-space 5.25 * $basic-space;

      @media screen and (max-height: 700px) {
        margin: 5 * $basic-space 0;
      }

      @media screen and (max-height: 550px) {
        margin: 2.5 * $basic-space 0;
      }
    }
  }
}
