@import 'variables/variables.scss';

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: $primary;
}

html {
  font-family: $font-family !important;
  font-size: $font-size-base-px;
}
#root {
  background: $primary;
  width: 100vw;
  min-width: $content-width;
  min-height: 768px;
  height: 100%;
  margin: 0 auto;
  position: relative;
  @media only screen and (min-width: 768px) and (max-width: 1025px) {
    max-width: 1024px;
    min-width: initial;
  }
}

.space {
  .ant-space-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $primary-color;
  border-color: $primary-color;
}
a,
svg,
img,
button,
input {
  cursor: pointer;
}

.ant-scroll-number-only {
  display: unset;
}

.modal-user-block {
  .ant-modal-header {
    display: none;
  }
  .ant-modal-footer {
    border-top: none;
  }
  .ant-modal-content {
    border-radius: 0px !important;
  }
  .ant-modal-body {
    min-height: 200px;
    justify-content: flex-start !important;

    .text-des {
      margin-top: 30px;
      white-space: pre;
    }
  }
}
.ant-menu-inline {
  border: none !important;
}
.ant-form-item-label > label,
.ant-select-selection-item,
.ant-typography,
.ant-select-selector,
a,
button,
input,
select,
p,
.ant-input {
  font-family: $font-family !important;
}

.list-empty {
  width: 345px;
  height: 170px;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
}
