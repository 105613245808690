@import 'variables/variables.scss';

.layout_container {
  height: calc(100% - 112px);

  max-height: 650px;
  background-color: $primary;
}
.container {
  justify-content: center;
  background-color: $primary;
  height: 100%;
  padding-top: 54px;
  .card_input {
    max-width: 600px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px $basic-space 2 * $basic-space #f3f3f3;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 2.5 * $basic-space;
  }

  .card_image {
    position: relative;
    width: 100%;
  }
  .top {
    width: 100%;
    position: relative;
  }

  .top_image {
    border-radius: 2.5 * $basic-space 2.5 * $basic-space 0 0;
    height: 150px;
    width: 100%;
    cursor: unset;
    // @media screen and (max-height: 550px) {
    //   height: 135px;
    // }
    // @media screen and (max-height: 400px) {
    //   height: 115px;
    // }
  }

  .logo {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 138px;
    height: 138px;
    border-radius: 50%;
    background-color: #fff;
    font-size: $font-size-bigger;
    color: $primary-color-title;
    font-weight: 500;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: $primary-color-logo;
    cursor: pointer;

    // @media screen and (max-height: 550px) {
    //   width: 120px;
    //   height: 120px;
    // }
    .logo_image {
      cursor: pointer;
      width: 70px;
      height: 50px;
    }
  }

  .form_input {
    display: flex;
    flex-direction: column;
    width: 100%;

    justify-content: center;
    height: 100%;
    padding: 0 100px;
    // @media screen and (max-height: 550px) {
    //   padding: 0 10 * $basic-space;
    // }
    // @media screen and (max-height: 400px) {
    //   justify-content: flex-end;
    // }
    // @media screen and (max-width: 960px) {
    //   padding: 0 10 * $basic-space;
    // }
    // @media screen and (max-width: 768px) {
    //   padding: 0 5 * $basic-space;
    // }
    // @media screen and (max-width: 400px) {
    //   padding: 0 2.5 * $basic-space;
    // }
    .form_title {
      font-family: $font-family;
      font-style: normal;
      font-weight: bold;
      font-size: $font-size-large;
      line-height: 1.6;
    }
    .form_item {
      width: 100%;
      min-height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0;
    }
    .btn {
      box-shadow: unset;
      border-color: unset;
      border: 1px solid $primary-color-button !important;
      background-color: $primary-color-button;
      width: 100%;
      height: 56px;
      border-radius: $basic-space;
      color: #ffffff;
      font-family: $font-family;
      font-size: $font-size-x-large;
      line-height: 1.1;
      margin-top: 2.5 * $basic-space;
      // @media screen and (max-height: 500px) {
      // }
      // @media screen and (max-height: 400px) {
      //   height: 40px;
      //   margin-top: 0;
      // }
    }
    .input {
      height: 56px;
      border-radius: 1.25 * $basic-space;
      font-family: $font-family;

      font-size: $font-size-large;
      line-height: 1.6;
      cursor: initial;
      // @media screen and (max-height: 500px) {
      //   margin-top: 5 * $basic-space;
      // }
      // @media screen and (max-height: 400px) {
      //   height: 40px;
      // }
    }
  }
}
