@import 'variables/variables.scss';
.container {
  background-color: $primary;
  justify-content: center;

  .cardSelect {
    height: 100%;
    max-width: 600px;

    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
  }

  .card {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 0px $basic-space 2 * $basic-space #f3f3f3;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-radius: 2.5 * $basic-space;
    padding: 12 * $basic-space 0;
    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) {
        min-height: initial;
      }
    }
  }
  .goBack {
    font-family: $font-family;
    font-style: normal;
    font-weight: normal;
    font-size: $font-size-large;
    line-height: 1.6;
    padding: 4 * $basic-space 0;
    cursor: pointer;
  }
  .title {
    font-family: $font-family;
    font-style: normal;
    font-weight: normal;

    font-family: $font-family;
    font-size: $font-size-large;
    line-height: 1.6rem;
    margin: 0px;
    margin-top: 2 * $basic-space;
  }
  .listItem {
    width: 100%;
    max-height: 324px;
    max-width: 346px;
    border: 0.25 * $basic-space solid #e1e1e1;
    box-sizing: border-box;
    border-radius: $basic-space;
    overflow: auto;
    overflow-x: hidden;
    cursor: pointer;
    font-family: $font-family;
    font-style: normal;
    font-weight: 500;
    font-size: $font-size-large;
    line-height: 1.6;
    color: #404040;
    & {
      li {
        width: 100%;
        word-break: break-word;
        text-align: left;
      }
    }
    li:first-child {
      border-radius: $basic-space $basic-space 0 0;
    }
    li:last-child {
      border-radius: 0 0 $basic-space $basic-space;
    }

    &::-webkit-scrollbar {
      width: $basic-space;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $primary-color-button;
      background-clip: content-box;
    }
  }
  .btn {
    background-color: $primary-color-button;
    box-shadow: unset;
    border-color: unset;
    border: 1px solid $primary-color-button !important;
    width: 100%;
    max-width: 346px;
    height: 48px;
    border-radius: 1.25 * $basic-space;
    color: #ffffff;
    font-family: $font-family;
    font-style: normal;
    font-weight: normal;
    font-size: $font-size-x-large;
    line-height: 1.1;
  }

  .btnDisabled {
    background-color: #f5f5f5 !important;
    border: 1px solid #c1bebe !important;
    color: #c1bebe;
  }
}

.formItem {
  width: 100%;
  max-width: 346px;
  & {
    .btnItem {
      margin: 0;
    }
  }
}
