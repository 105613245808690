@import 'variables/variables.scss';

.wrapper {
  width: 100%;
  height: 100%;

  .row {
    width: 100%;
    margin: 0;
    box-sizing: border-box;
    overflow: auto;
    overflow-x: hidden;
    .col {
      .ant-card {
        border-radius: 2 * $basic-space !important;
        box-shadow: 0px $basic-space 2 * $basic-space #f3f3f3;
        margin-bottom: 5 * $basic-space;
      }
      .ant-card-head {
        border-radius: 2 * $basic-space 2 * $basic-space 0 0;
        font-weight: bold;
        font-size: $font-size-large;
        line-height: 1.5;
        color: #ffffff;
        text-align: justify;
        background-color: $primary-color-button;
      }
      .space_sos {
        max-width: 100%;
        .ant-space-item:last-child {
          overflow: hidden;
          text-overflow: ellipsis;
          a {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }
      }
      .sos_phone {
        text-decoration: unset !important;
      }
      .ant-card-meta-description {
        max-width: 100%;
      }
      .ant-card-meta-description a {
        font-size: $font-size-large;
        line-height: 1.6;
        text-decoration-line: underline;
        color: #404040;
      }
    }
  }
}
.sos {
  overflow: auto;
  
  .row-nodata {
    height: 100%;
    
    .col_notfound {
      box-shadow: 4px 8px 20px #f4f4f4;
      border-radius: $basic-space;
      display: flex !important;
      background: #fff;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      & {
        .ant-image {
          max-width: 140px;
        }
        .notfound_text {
          font-size: $font-size-bigger;
          line-height: 1.5;
          display: flex;
          align-items: center;
          text-align: center;
          color: #a8a8a8;
          max-width: 246px;
          padding: 0 5 * $basic-space;
        }
      }
    }
  }
  
}
