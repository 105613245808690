@import 'variables/variables.scss';
.ant-modal-content {
  border-radius: 2*$basic-space !important;
  width: 100%;
}

.ant-modal-body {
  width: 100%;
  padding: 6*$basic-space !important;
  border-radius: 2*$basic-space;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
