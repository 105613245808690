@import 'variables/variables.scss';
.incident_detail {
  width: 100%;
  height: 100%;
  justify-content: center;
  .card_input {
    max-width: 600px;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .form_incident {
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 2.5 * $basic-space;
    box-shadow: 0px 4px 8px #f3f3f3;
    padding-top: 6 * $basic-space;
    padding-left: 5 * $basic-space;
    padding-right: 5 * $basic-space;
    padding-bottom: 4 * $basic-space;
    & {
      .question_title {
        display: flex;
        align-items: center;
        margin-bottom: 3.5 * $basic-space;
        font-family: $font-family;
      }
      .form_input {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        .title {
          font-size: $font-size-large;
          line-height: 1.6;
          text-align: left;
          color: #404040;
          font-family: $font-family;
        }
        .form_title {
          font-family: $font-family;
          font-style: normal;
          font-weight: bold;
          font-size: $font-size-x-large;
          line-height: 1.5;
          color: #404040;
        }

        .form_message {
          text-transform: uppercase;

          font-size: $font-size-normal;
          line-height: 1.5;
          color: #404040;
          margin: 0;
          padding: 0;
          font-family: $font-family;
        }
        .form_item {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .btnsubmit {
          background-color: $primary-color-button;
          width: 100%;
          height: 48px;
          border-radius: $basic-space;
          color: #ffffff;
          font-family: $font-family;
          font-style: normal;
          font-weight: normal;
          font-size: $font-size-x-large;
          line-height: 1.1;
          margin-top: 2.5 * $basic-space;
          font-family: $font-family;
        }
        .btnupload {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 48px;
          font-size: $font-size-large;
          line-height: 1.6;
          box-sizing: border-box;
          border-radius: $basic-space;
          font-family: $font-family;

          color: #404040;
        }
        .btndropdown {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 48px;
          font-size: $font-size-large;
          line-height: 1.6;
          box-sizing: border-box;
          border-radius: $basic-space;
          color: #777777;
          font-family: $font-family;

          & {
            .anticon {
              width: 100%;
              text-align: right;
            }
          }
        }
        .input {
          height: 48px;
          min-height: 48px !important;
          border-radius: 1.25 * $basic-space;
          font-family: $font-family;
          color: #404040;
          font-style: normal;
          font-weight: normal;
          font-size: $font-size-large;
          line-height: 1.6;
          cursor: pointer;
        }
        .inputarea {
          height: 48px;
          color: #404040;
          border-radius: 1.25 * $basic-space;
          font-family: $font-family;
          font-style: normal;
          font-weight: normal;
          font-size: $font-size-large;
          line-height: 1.6;
          cursor: pointer;
        }
      }
    }
  }
}
